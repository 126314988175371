import store from '@/store';

export default {
	getPayload() {
		var payload = {};

		payload.microsite = store.getters['microsite/data']?.id;
		payload.landing = store.getters['marketing/landing'];
		payload.slug = store.getters['cart/slug'];
		payload.type = store.getters['cart/type'];
		payload.when = store.getters['cart/when'];
		payload.payment = store.getters['cart/payment'];
		payload.items = store.getters['cart/items'];
		payload.subtotal = store.getters['cart/subtotal'];
		payload.discount = store.getters['cart/discount'];
		payload.delivery_amount = store.getters['cart/deliveryAmount'];
		payload.total = store.getters['cart/total'];
		payload.comments = store.getters['cart/comments'];

		if(payload.type.option == 'DELIVERY') payload.address = store.getters['location/address'];
		if(store.getters['code/isDefined']) payload.code = store.getters['code/info'].code;
		if(store.getters['cart/hasPromocode']) payload.promocode = store.getters['cart/promocode'].code;

		return payload;
	}
}