<template>
	<layouts-cart-minimum :buttonBack="{ description: 'al detalle', path: '/cart' }">
		<div class="pb-24">
			<div id="total-description" class="mx-n1 px-1 py-3 sticky-top">
				<store-card-small :store="current_store" subtitle="Último paso para terminar tu pedido." />
				<hr class="my-4" />
				<div class="d-flex justify-content-between mb-2" v-if="subtotal != total">
					<h6 class="h6">Subtotal</h6>
					<h6 class="h6 text-nowrap">{{ vueNumberFormat(subtotal, $personalizer.currency.value) }}</h6>
				</div>
				<div class="d-flex justify-content-between mb-2" v-if="discount > 0">
					<h6 class="h6 text-danger">Descuento</h6>
					<h6 class="h6 text-danger text-nowrap">{{ vueNumberFormat(discount, $personalizer.currency.value) }}</h6>
				</div>
				<div class="d-flex justify-content-between mb-2" v-if="(type && type.option == 'DELIVERY')">
					<h6 class="h6">Envío</h6>
					<h6 class="h6 text-nowrap">{{ vueNumberFormat(deliveryAmount, $personalizer.currency.value) }}</h6>
				</div>
				<div class="d-flex justify-content-between">
					<h4 class="h4">Total</h4>
					<h4 class="h4 text-nowrap">{{ vueNumberFormat(total, $personalizer.currency.value) }}</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-12 my-3">
					<a href="javascript:void(0);" class="btn btn-neutral w-100" @click="menuPromocodeShow" v-if="!hasPromocode"><i class="bi bi-collection me-2"></i> Usar código promocional</a>
					<a href="javascript:void(0);" class="btn btn-neutral w-100" @click="promocodeRemove" v-if="hasPromocode"><i class="bi bi-trash me-2"></i> Quitar código promocional</a>
				</div>
				<div id="checkout-type" class="col-12 mt-4">
					<cart-checkout-type :displayOptions="displayOptions" :services="services" :store="current_store" :loading="loading" @updateLoading="updateLoading" />
				</div>
				<div id="checkout-when" class="col-12 mt-7" v-if="type && !hasCode">
					<cart-checkout-when :displayOptions="displayOptions" :services="services" :store="current_store" :loading="loading" @updateLoading="updateLoading" />
				</div>
				<div id="checkout-payment" class="col-12 mt-7" v-if="when && !hasCodeGroup">
					<cart-checkout-payment :displayOptions="displayOptions" :store="current_store" :loading="loading" @updateLoading="updateLoading" />
				</div>
				<div id="checkout-comments" class="col-12 mt-7">
					<label class="form-label"><h5 class="mb-2">¿Necesitás aclarar algo?</h5></label>
					<input type="text" class="form-control" v-model="comments" placeholder="Agregar aclaración" maxlength="100">
				</div>
			</div>
		</div>
		<div class="fixed-bottom bg-white">
			<div class="container py-4">
				<div class="row">
					<div class="d-grid gap-2 col-12 col-md-6 mx-auto">
						<button class="btn btn-dark" type="button" :disabled="submitting" @click="submit">Enviar pedido</button>
					</div>
				</div>
			</div>
		</div>
		<cart-checkout-menu-promocode @setPromocode="setPromocode" />
	</layouts-cart-minimum>
</template>

<script>
	import { ref, watch, computed, onMounted, onBeforeMount } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import StoreRepository from '@/repositories/StoreRepository';
	import OrdersRepository from '@/repositories/OrdersRepository';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';
	import CartHelper from '@/helpers/Cart';
	import VueScrollTo from 'vue-scrollto';
	import { useToast } from 'vue-toastification';
	import { Offcanvas } from 'bootstrap';

	export default {
		setup() {
			const toast = useToast();
			const { isDefined, current_store, services, load } = composableStore();
			const { submitting, catchError } = composableForm();
			const comments = ref(store.getters['cart/comments']);
			const hasPromocode = computed(() => store.getters['cart/hasPromocode']);
			const type = computed(() => store.getters['cart/type']);
			const when = computed(() => store.getters['cart/when']);
			const payment = computed(() => store.getters['cart/payment']);
			const items = computed(() => store.getters['cart/items']);
			const subtotal = computed(() => store.getters['cart/subtotal']);
			const discount = computed(() => store.getters['cart/discount']);
			const deliveryAmount = computed(() => store.getters['cart/deliveryAmount']);
			const total = computed(() => store.getters['cart/total']);
			const displayOptions = computed(() => (isDefined.value && !!services.value));

			const hasCode = computed(() => store.getters['code/isDefined']);
			const hasCodeGroup = computed(() => {
				if(hasCode.value) return store.getters['code/group'];
				else return false;
			});

			const loadServices = async () => {
				await StoreRepository.getExtras(store.getters['cart/slug'], store.getters['location/geolocation'])
					.then((response) => {
						store.dispatch('store/setServices', response.data.services);
					});
			}

			onBeforeMount(() => {
				load(store.getters['cart/slug']);
				loadServices();

				if(hasCode.value) {
					store.dispatch('cart/setType', { option: 'LOCAL' });
					store.dispatch('cart/setWhen', { option: 'immediate' });
				}
			});

			const submit = () => {
				if(submitting.value) return;

				if(!type.value) {
					VueScrollTo.scrollTo('#checkout-type', 100, {offset: () => { return (document.getElementById('total-description').clientHeight + 10) * -1; }});
					toast.error('Seleccione el tipo de servicio.');
					return false;
				}

				if(!when.value && !hasCode.value) {
					VueScrollTo.scrollTo('#checkout-when', 100, {offset: () => { return (document.getElementById('total-description').clientHeight + 10) * -1; }});
					toast.error('Seleccione el momento de entrega.');
					return false;
				}

				if(!payment.value && !hasCode.value) {
					VueScrollTo.scrollTo('#checkout-payment', 100, {offset: () => { return (document.getElementById('total-description').clientHeight + 10) * -1; }});
					toast.error('Seleccione la forma de pago.');
					return false;
				}

				submitting.value = true;
				loading.value = true;
				var payload = CartHelper.getPayload();
				OrdersRepository.create(payload).then((response) => {
					router.push({name: 'cart.complete', params: {id: response.data.order.id}});
					store.dispatch('cart/empty');
				}).catch((e) => {
					catchError(e, true);
					loading.value = false;
				});
			}

			const loading = ref(false);
			const updateLoading = (value) => loading.value = value;

			watch(comments, (newValue) => store.dispatch('cart/setComments', newValue));

			const promocodeRemove = () => {
				store.dispatch('cart/setPromocode', null);
			}

			const menuPromocode = ref(null);
			onMounted(() => {
				menuPromocode.value = new Offcanvas(document.getElementById('menu-promocode'));

				document.getElementById('menu-promocode').addEventListener('shown.bs.offcanvas', () => {
					document.querySelector('#menu-promocode #code').value = '';
					document.querySelector('#menu-promocode #code').focus();
				});
			});

			const menuPromocodeShow = () => menuPromocode.value.show();

			const setPromocode = (promocode) => {
				store.dispatch('cart/setPromocode', promocode);
				menuPromocode.value.hide();
			}

			return { isDefined, current_store, menuPromocodeShow, setPromocode, promocodeRemove, services, comments, hasCode, hasCodeGroup, hasPromocode, type, when, payment, items, subtotal, discount, deliveryAmount, total, displayOptions, submitting, submit, loading, updateLoading };
		}
	}
</script>

<style scoped>
	.container {
		max-width: 650px !important;
	}

	#total-description {
		background-color: var(--bs-body-bg) !important;
	}
</style>